var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Add Schema Post "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Type","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Type","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Post "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select Schema","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"cate","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"data-testid":"post-schema-select","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.categoriesList},on:{"input":function($event){return _vm.getSchema(_vm.addCourseForm.schemaable_id)}},model:{value:(_vm.addCourseForm.schemaable_id),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "schemaable_id", $$v)},expression:"addCourseForm.schemaable_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Type ","label-for":"location-type"}},[_c('validation-provider',{attrs:{"name":"Type","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"data-testid":"schema-type-select","options":['NewsArticle','Article','BlogPosting']},model:{value:(_vm.PostData['@type']),callback:function ($$v) {_vm.$set(_vm.PostData, '@type', $$v)},expression:"PostData['@type']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"headline","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"cate","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"schema-post-headline"},model:{value:(_vm.PostData.headline),callback:function ($$v) {_vm.$set(_vm.PostData, "headline", $$v)},expression:"PostData.headline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"image","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"cate","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"schema-post-image"},model:{value:(_vm.PostData.image),callback:function ($$v) {_vm.$set(_vm.PostData, "image", $$v)},expression:"PostData.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","xl":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"date Published","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"datePublished","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"data-testid":"post-pub-date"},model:{value:(_vm.PostData.datePublished),callback:function ($$v) {_vm.$set(_vm.PostData, "datePublished", $$v)},expression:"PostData.datePublished"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","xl":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"date Modified","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"dateModified","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"data-testid":"post-mod-date"},model:{value:(_vm.PostData.dateModified),callback:function ($$v) {_vm.$set(_vm.PostData, "dateModified", $$v)},expression:"PostData.dateModified"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Type ","label-for":"location-type"}},[_c('validation-provider',{attrs:{"name":"locationType","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"data-testid":"post-type-select","options":['Orgnization','Person']},model:{value:(_vm.PostData.author[0]['@type']),callback:function ($$v) {_vm.$set(_vm.PostData.author[0], '@type', $$v)},expression:"PostData.author[0]['@type']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"author ","label-for":"location-type"}},[_c('validation-provider',{attrs:{"name":"locationType","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"post-author-name"},model:{value:(_vm.PostData.author[0].name),callback:function ($$v) {_vm.$set(_vm.PostData.author[0], "name", $$v)},expression:"PostData.author[0].name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Location Name","label-for":"location-name"}},[_c('validation-provider',{attrs:{"name":"author Url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"post-location"},model:{value:(_vm.PostData.author[0].url),callback:function ($$v) {_vm.$set(_vm.PostData.author[0], "url", $$v)},expression:"PostData.author[0].url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[(!_vm.isLoading)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"data-testid":"schema-post-submit","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")]):_vm._e(),(_vm.isLoading)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":""}},[_vm._v(" Loading... "),_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"sr-only"})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }